<template>
    <div class="service-agreement">
        <Header />
        <div class="w-1200 service-agreement-main">
            <div class="service-agreement-title">平台服务条款<div class="right"><span>机械租赁平台</span><span class="date">2022-07-17</span></div>
            </div>
            <div class="service-agreement-body">
                <div class=WordSection1 style='layout-grid:15.6pt'>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>欢迎注册械租租平台！</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>以下内容是《</span><a style='mso-comment-reference:lc_1;mso-comment-date:20220822T1723'><span style='font-size:13.0pt'> </span></a><span style='mso-comment-continuation:
1'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>械租租机</span></span><span class=MsoCommentReference><span lang=EN-US>

                                <span style='mso-special-character:comment'>&nbsp;</span>
                            </span></span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>械使用协议》，（以下简称“本协议”），在你注册械租租平台前请务必谨慎阅读，充分理解协议中的相关条款内容。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>请你务必审慎阅读、充分理解协议中相关条款内容，特别是粗体标注的内容。你一旦注册械租租平台，即视为你已了解并完全同意本协议各项内容，包括械租租平台对使用协议随时所做的任何修改。如你不同意本协议及</span><span lang=EN-US style='font-size:13.0pt'>/</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>或随时对其的修改，请你立即停止注册及使用</span><span style='font-size:13.0pt'> <span lang=EN-US><span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp;&nbsp;</span></span></span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>平台所提供的全部服务。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='margin-left:0cm;text-indent:0cm;line-height:22.0pt;
mso-line-height-rule:exactly;mso-list:l0 level1 lfo1'>
                        <span lang=EN-US style='font-size:13.0pt;mso-fareast-font-family:Calibri;mso-fareast-theme-font:
minor-latin;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><span style='mso-list:Ignore'>1、</span></span>
                        <span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>接受条款</span><span lang=EN-US style='font-size:13.0pt'>

                        </span>
                    </p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>1.1 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>械租租工程机械互联网综合服务平台的运营者及相关关联公司（以下简称“械租租）根据本使用协议的条款及不时发布的规则为你提供基于械租租不限于</span><span lang=EN-US style='font-size:13.0pt'>PC</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>端、移动端（</span><span lang=EN-US style='font-size:13.0pt'>APP</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>）等相关客户端）的互联网服务。本协议的条款可由<a name="_Hlk124523833">械租租</a>随时修改，修改后的使用协议在网站上一经公布即有效代替原来的使用协议。你一旦在械租租注册，即成为械租租用户（以下简称“用户”或“你”），并受本协议的约束。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>1.2 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>当你使用械租租</span><span lang=EN-US style='font-size:13.0pt'>&nbsp;</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>单项服务时，你和械租租应遵守械租租</span><span lang=EN-US style='font-size:13.0pt'>&nbsp;</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>随时公布的与该服务相关的指引和规则。前述所有的指引和规则，均构成本使用协议的一部分。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>1.3 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你应遵守本协议的各项条款，合法合理使用械租租提供的服务，否则，械租租有权依据本协议中断或终止为你提供服务。同时，械租租保留在任何时候收回你所使用的账号的权利。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、服务说明</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>械租租向用户提供包括但不限于如下服务：</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>为用户提供设备租赁信息发布与查阅、维修点查询、物流运输、配件买卖等服务。非本协议另有其它明示规定，械租租增加或强化目前本服务的任何新功能，包括所推出的新产品，均受本使用协议之规范。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、用户注册义务</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>3.1 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>为了能使用本服务，按照中国法律的要求，你同意以下事项：依本服务注册提示填写你正确的手机号码、密码等信息，并确保今后更新的登录、头像及手机号码等资料的真实性、有效性和合法性。若你提供任何违法、不道德或械租租认为不适合在</span><span style='font-size:13.0pt'> <span lang=EN-US><span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>平台上展示的资料；或者械租租有理由怀疑你的行为属于程序或恶意操作，</span><span style='font-size:13.0pt'> <span lang=EN-US><span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>有权无须事先通知即可暂停或终止你使用账号，并拒绝你于现在和未来使用本服务之全部或任何部分。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>4</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、用户账号、密码及安全</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>4.1 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>完成本服务的注册程序并成功注册之后，你可使用你的注册手机号码和密码，登录到你在械租租平台的账号（下称“账号”）。注册完成之时，你便获得了账号的使用权。保护账号安全，是你的责任。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>4.2 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你应对所有使用你的账号的行为负完全的责任。你同意：</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）你械租租账号遭到未获授权的使用，或者发生其它任何安全问题时，你将立即通知械租租；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）如果你未保管好自己的账号和密码，因此而产生的任何损失或损害，械租租不承担任何责任；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）你要对使用账号的所有行为给你、械租租或第三方造成的损害负全责，包括你未保管好自己的账号或密码的情形。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>4.3 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>由于通过账号可获取到用户的个人信息，且账号的所有权归械租租所有，除法律明文规定外，未经械租租同意，用户不得将账号转让、出售或出借给他人使用。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>4.4 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>若你连续械租租个月未登录械租租平台，且不存在未到期的有效业务，械租租有权限制你对账号的使用。由此造成的不利后果由你自行承担。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>4.5 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你选择将械租租账号与第三方账号进行绑定的，除你自行解除绑定关系外，如发生下列任何一种情形，你已绑定的第三方账号也有可能被解除绑定而械租租无需对你或任何第三方承担任何责任：</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）你违反法律法规、国家政策或本协议的；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）你违反第三方账户用户协议或其相关规定的；</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）其他需要解除绑定的情形</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='margin-left:0cm;text-indent:0cm;line-height:22.0pt;
mso-line-height-rule:exactly;mso-list:l2 level1 lfo2'>
                        <span lang=EN-US style='font-size:13.0pt;mso-fareast-font-family:Calibri;mso-fareast-theme-font:
minor-latin;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><span style='mso-list:Ignore'>5、</span></span>
                        <span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>服务变更、中断或终止</span><span lang=EN-US style='font-size:13.0pt'>

                        </span>
                    </p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>5.1 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>械租租可能会对服务内容进行变更、也可能会中断、终止服务。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>5.2 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为或械租租无法控制的情形），你同意械租租有权随时中断或终止部分或全部的服务。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>5.3 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你理解，械租租需要定期或不定期对提供服务的平台或相关设备进行维护、升级或其他目的暂停部分或全部服务，如因此类情况而造成服务在合理时间内的中断，械租租无需为此承担任何责任。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>5.4 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>如发生以下任何一种情形，械租租有权随时中断或终止向你提供本协议项下的服务而无需对你或任何第三方承担责任：</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）你提供个人资料不真实；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）发布任何与本平台所提供服务（见第</span><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>条款）不相符或无关的信息；</span><span style='font-size:13.0pt'> </span><span style='font-size:13.0pt;font-family:
宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>发布任何不完整、虚假的信息；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='margin-left:0cm;text-indent:0cm;line-height:22.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo3'>
                        <span lang=EN-US style='font-size:13.0pt;mso-fareast-font-family:Calibri;mso-fareast-theme-font:
minor-latin;mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><span style='mso-list:Ignore'>3）</span></span>
                        <span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你违反法律、社会道德、政策或违反本使用协议。</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span>
                    </p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>5.5 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你同意械租租基于其自行之考虑，因任何理由，包含但不限于缺乏使用，或械租租认为你已经违反本使用协议，终止你的账号或本服务之使用（或服务之任何部分），并将你在本服务内任何内容加以移除并删除。你同意依本使用协议任何规定提供之服务，无需进行事先通知即可中断或终止。你承认并同意，械租租可立即关闭或注销你的账号及删除你账号中所有相关信息及文件，及</span><span lang=EN-US style='font-size:13.0pt'>/</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>或禁止继续使用前述文件或本服务。此外，你同意若本服务之使用被中断或终止或你的账号及相关信息和文件被关闭或注销，械租租对你或任何第三人均不承担任何责任。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>6</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、担保与保证</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>你明确了解并同意∶</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>6.1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>械租租负责向你提供械租租平台服务。但械租租对械租租平台服务不作任何明示或暗示的保证，包括但不限于械租租平台服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>6.3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>械租租不保证以下事项∶</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>·</span><span style='font-size:13.0pt'> </span><span style='font-size:13.0pt;font-family:
宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>本服务将符合你的要求；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>·</span><span style='font-size:13.0pt'> </span><span style='font-size:13.0pt;font-family:
宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>本服务将不受干扰、及时提供、安全可靠或不会出错；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>·</span><span style='font-size:13.0pt'> </span><span style='font-size:13.0pt;font-family:
宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>使用本服务取得之结果正确可靠；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>·</span><span style='font-size:13.0pt'> </span><span style='font-size:13.0pt;font-family:
宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>你经由本服务购买或取得之任何产品、服务、资讯或其它信息将符合你的期望；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>6.5</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你自械租租或经由本服务取得的任何建议或信息，无论是书面或口头形式，除非本使用协议有明确规定，将不构成本使用协议以外之任何保证。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>7</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、知识产权</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>7.1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>械租租在本服务中提供的内容（包括但不限于编码、网页、界面设计、版面框架、文字、音频、视频、图片或其他资料等）等知识产权归械租租或相关权利人所有。除另有约定外，械租租提供本服务时所依托的软件、系统等的著作权、专利权及其他知识产权归械租租所有。械租租平台以及其他械租租标识及产品、服务名称，均为</span><span lang=EN-US style='font-size:13.0pt'><span style='mso-spacerun:yes'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>之商标。未经械租租事先书面同意，你不得擅自删除、掩盖或更改械租租的版权声明、商标或其它权利声明，不得将械租租的商标以任何方式展示或使用或作其它处理，或表示你有权展示、使用或另行处理械租租商标。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>7.2 </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你在械租租平台上传或发布的信息或内容，并不代表械租租之意见及观点，也不意味着神械租租赞同其观点或证实其内容的真实性。用户通过械租租平台发布的信息、文字、图片等资料均由械租租用户提供，其真实性、准确性和合法性由信息发布人负责。械租租不提供任何保证，并不承担任何法律责任。如果以上资料侵犯了第三方的知识产权或其他权利，责任由信息发布者本人承担，械租租对此不承担责任</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>8.1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>信息收集</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>你在使用械租租提供的服务时，为了更好地实现你的目的，我们可能收集和使用你的个人信息，如你拒绝，你可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。收集的情形包括：</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）你提供的信息，包括但不限于：</span><span lang=EN-US style='font-size:13.0pt'>&nbsp;</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>你在注册械租租账号时提供的信息。例如：电话号码、昵称等。你在使用</span><span style='font-size:13.0pt'> </span><span style='font-size:13.0pt;font-family:
宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>械租租的过程中向我们提供的信息。例如：在购买需要邮寄的物品时提供的地址信息和支付方式等。你在开通“认证功能、保证金缴纳、提现”等具有收款功能的产品时向我们提供的身份证号码、收款方式（包括第三方平台信息、银行账号等）等。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='margin-left:0cm;text-indent:0cm;line-height:22.0pt;
mso-line-height-rule:exactly;mso-list:l3 level1 lfo4'>
                        <span lang=EN-US style='font-size:13.0pt;mso-bidi-font-family:宋体;mso-bidi-theme-font:
minor-fareast'><span style='mso-list:Ignore'>（2）</span></span>
                        <span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>在你使用服务的过程中获取的信息，包括但不限于：</span><span lang=EN-US style='font-size:13.0pt'>&nbsp;</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>在使用与地理位置相关的服务时的地理定位信息。在使用浏览器或者移动应用时的浏览器、移动设备信息、</span><span lang=EN-US style='font-size:13.0pt'>IP</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>地址等。在使用服务的过程中产生的信息。例如访问页面</span><span lang=EN-US style='font-size:
13.0pt'>URL</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:
Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>、搜索词、发表的内容或图片等。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span>
                    </p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）</span><span lang=EN-US style='font-size:13.0pt'>Cookie</span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>使用</span><span lang=EN-US style='font-size:13.0pt'> Cookie </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>能帮助你实现你的联机体验的个性化，你可以接受或拒绝</span><span lang=EN-US style='font-size:
13.0pt'> Cookie </span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:
Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>，大多数</span><span lang=EN-US style='font-size:13.0pt'> Web </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>浏览器会自动接受</span><span lang=EN-US style='font-size:13.0pt'> Cookie</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>，但你通常可根据自己的需要来修改浏览器的设置以拒绝</span><span lang=EN-US style='font-size:13.0pt'> Cookie</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>。为了让你在访问械租租时能得到更好的服务，械租租有时会使用</span><span lang=EN-US style='font-size:13.0pt'> Cookie </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>以便知道哪些网站受欢迎。</span><span lang=EN-US style='font-size:13.0pt'>Cookie</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>不会跟踪个人信息。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>当你注册械租租时，械租租亦会使用</span><span lang=EN-US style='font-size:13.0pt'> Cookie</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>。在这种情况下，械租租会收集并存储有用信息，当你再次访问械租租时，我们可辨认你的身份。来自械租租的</span><span lang=EN-US style='font-size:13.0pt'> Cookie </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>只能被械租租读取。如果你的浏览器被设置为拒绝</span><span lang=EN-US style='font-size:
13.0pt'> Cookie</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:
Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>，你仍然能够访问械租租的大多数网页。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）网站信标和像素标签</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>除</span><span lang=EN-US style='font-size:13.0pt'> Cookie </span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向你发送的电子邮件可能含有链接至我们网站内容的点击</span><span lang=EN-US style='font-size:13.0pt'> URL</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>。如果你点击该链接，我们则会跟踪此次点击，帮助我们了解你的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果你不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</span><span style='font-size:13.0pt'> <span lang=EN-US>

                            </span></span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）</span><span lang=EN-US style='font-size:13.0pt'>Do Not Track(</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>请勿追踪</span><span lang=EN-US style='font-size:13.0pt'>)</span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>很多网络浏览器均设有</span><span lang=EN-US style='font-size:13.0pt'> Do Not Track </span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>功能，该功能可向网站发布</span><span lang=EN-US style='font-size:13.0pt'> Do Not Track </span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果你的浏览器启用了</span><span lang=EN-US style='font-size:13.0pt'> Do Not Track</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>，那么我们的网站都会尊重你的选择。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>8.2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>信息使用</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'><span style='mso-spacerun:yes'>&nbsp;
                            </span></span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:
Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>我们会在法律法规要求或械租租相关服务条款、软件许可使用协议约定的情况下因如下目的使用你的个人信息：</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>1</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）向你提供你使用的各项服务，并维护、改进这些服务。</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）我们可能使用你提供的个人信息中的联系方式与你通信，例如通知你有关你的账户、安全性更新、产品和服务信息。为了更好的为你提供服务，请你及时查看械租租发送的相关信息。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）向你推荐你可能感兴趣的内容，包括但不限于向你发出产品和服务信息，或通过系统向你展示个性化的第三方推广信息，如果你对我们推荐的内容不感兴趣，请及时回复取消内容的通知。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>4</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）我们可能会将来自某项服务的个人信息、交易信息与来自其他服务所获得的信息结合起来，进行综合统计、分析或加工等处理，以便为你提供更加个性化的服务。</span><span style='font-size:13.0pt'> <span lang=EN-US>

                            </span></span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>5</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）经你同意的其他目的。</span><span style='font-size:13.0pt'> <span lang=EN-US>

                            </span></span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>需要提醒你注意，以下情形中，使用你的个人信息无需你的同意：</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(1)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>与国家安全、国防安全直接相关的。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(2)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>与公共安全、公共卫生、重大公共利益直接相关的。</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(3)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>与犯罪侦查、起诉、审判和判决执行等直接相关的。</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(4)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(5)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>所收集的个人信息是你自行向社会公众公开的。</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(6)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开等渠道。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(7)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>根据你的要求签订和履行合同所必需的。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(8)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>(9)</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>法律法规规定的其他情形。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>6.2</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>械租租仅向您提供械租租平台服务，您了解械租租平台上的信息系用户自行发布，由于海量信息的存在，且械租租平台无法杜绝可能存在风险和瑕疵。您应谨慎判断确定相关信息的真实性、合法性和有效性，并注意保留相应的证据以利于维权，如可能，尽量采用平台建议的交易方式进行。是否使用本服务下载或取得任何资料应由你自行考虑且自负风险，不对用户所发布信息的删除或储存失败负责。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>6.4</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你同意在使用械租租平台服务过程中显示械租租自行或由第三方服务商向你发送的推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向你特别通知而变更。除法律法规明确规定外，你应自行对依该推广信息进行的交易负责，对用户因依该推广信息进行的交易或前述第三方服务商提供的内容而遭受的损失或损害，械租租不承担任何责任。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>7.3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>除法律另有强制性规定外，未经械租租明确的特别书面许可，任何单位或个人不得以任何方式非法地全部或部分复制、传播、展示、镜像、上载、下载、转载、引用、链接、抓取或以其他方式使用械租租的信息内容，否则，械租租有权追究其法律责任。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>8.4&nbsp;</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>如你违反世界版权公约、中华人民共和国著作权法、商标法、专利法、反不正当竞争法及其他与知识产权方面相关的法律法规或本协议约定，你应自行承担因此而给他人（包括械租租）造成的损害，承担相应的法律责任。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>8</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、用户隐私</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）来自第三方的信息</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>为了给您提供更好、更优、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，械租租的关联方、合作伙伴会依据法律的规定或与您的约定或征得您同意的前提下，向械租租平台分享您的个人信息。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你了解并同意，以下信息不适用本隐私权条款：</span><span lang=EN-US style='font-size:
13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;1</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）你在使用械租租平台提供的站内搜索服务时输入的关键字信息；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;2</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）在你未选择“匿名评价”功能时，械租租收集到的你在械租租平台进行评价的有关数据，包括但不限于用户名、商品名称及评价详情等；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）信用评价、违反法律规定或违反械租租规则行为及械租租已对你采取的措施；</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;4</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）应法律法规要求需公示的企业名称等相关工商注册信息以及自然人经营者的信息。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你知悉并认可：械租租可能会与第三方合作向用户提供相关的网络服务，在此情况下，如械租租或旗下相应主体已与该第三方签署了适当的保密条款，则械租租有权将用户的注册信息等资料提供给该第三方，并要求该第三方按照我们的隐私权条款以及其他任何相关的保密和安全措施来为我们处理上述信息。另外，在不透露单个用户隐私资料的前提下，械租租有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。械租租可能会对网络服务使用情况进行统计。同时，械租租可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势。这些统计信息不包含您的任何身份识别信息。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>4</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>）</span><span lang=EN-US style='font-size:13.0pt'>Cookie</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>和同类技术的使用</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>8.3</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>你的个人信息保护</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>保护你个人信息安全既是法律要求，也是械租租长期坚持的一项基本原则。你提供的注册资料及械租租保留的有关你的其它资料将受到中国有关法律的保护。械租租将根据中国法律、本协议的规定收集、存储、使用和透露你的个人信息。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>1</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>）我们仅在本隐私条款所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使你的信息不会被泄漏、毁损或者丢失，包括但不限于</span><span lang=EN-US style='font-size:13.0pt'>SSL</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、信息加密存储、数据中心的访问控制。我们对可能接触到你的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。械租租会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，械租租将尽力做到使您的信息不被泄漏、毁损或丢失。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>2</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>）你的账户均有安全保护功能，请妥善保管你的账户及密码信息。械租租将通过向其它服务器备份、对用户密码进行加密等安全措施确保你的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请你理解，由于技术的限制以及可能存在的各种恶意手段，即便竭尽所能加强安全措施，在信息网络上也不存在“完善的安全措施”。如因你自己的原因导致账户及密码信息泄露而造成的任何法律后果需由你本人负责。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>&nbsp;&nbsp;</span><span style='font-size:
13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>（</span><span lang=EN-US style='font-size:13.0pt'>3</span><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>）在使用械租租平台服务进行网上交易时，如你不可避免地要向交易对方或潜在的交易对方披露自己的个人信息（例如联系人、联络方式等），请你妥善保护自己的个人信息，仅在必要的情形下向他人提供。如你发现自己的个人信息已经被泄露或者存在被泄露的可能，且有可能会危及你注册获得的械租租账户安全，或者给你造成其他的损失的，请你务必在第一时间通知械租租客服，以便械租租采取相应措施维护你的</span><span style='font-size:13.0pt'> </span><span style='font-size:13.0pt;font-family:
宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>账户安全，防止损失的发生或者进一步扩大。为避免疑义，如果你未在第一时间通知客服，则你应自行承担由此给你造成的损失（及扩大的损失）。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span lang=EN-US style='font-size:13.0pt'>9</span><span style='font-size:13.0pt;
font-family:宋体;mso-ascii-font-family:Calibri;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-fareast;mso-hansi-font-family:Calibri;mso-hansi-theme-font:
minor-latin'>、争议解决</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                    <p class=MsoNormal style='line-height:22.0pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt;font-family:宋体;mso-ascii-font-family:Calibri;
mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:minor-fareast;
mso-hansi-font-family:Calibri;mso-hansi-theme-font:minor-latin'>你与械租租因本协议涉及的一切争议，均提请福州仲裁委员会按照其仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。</span><span lang=EN-US style='font-size:13.0pt'>

                        </span></p>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
import Header from "@/components/header"
export default {
    components: {
        Header
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
